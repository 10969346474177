
export const links = [
  {
    id: 1,
    url: '/',
    text: 'home',
  },
  {
    id: 2,
    url: '/about',
    text: 'About Me',
  },
  {
    id: 3,
    url: '/projects',
    text: 'Projects',
  },
  {
    id: 4,
    url: '/learning',
    text: 'Learning',
    submenu: [
      {
        title: 'Animation',
        url:'/animation'
      },
      {
        title: 'Rigging',
        url:'/rigging'
      },
    ]
  },
  {
    id: 5,
    url: '/services',
    text: 'Services',
    submenu: [
      {
        title: 'Animation',
        url:'/animation'
      },
      {
        title: 'Rigging',
        url:'/rigging'
      },
    ]
  },
  {
    id: 6,
    url: '/contact',
    text: 'Contact',
  },
];

export const projects = [
  {
    id: 1,
    title: 'Bachelor Thesis Project [RO]',
    img:'/images/licenta-icon.gif',
    desc: 'Simularea comportamentului dinamic al unui automobil in jocuri 3d'
  },
  {
    id: 2,
    title: 'Bachelor Thesis Project [RO]',
    img:'/images/licenta-icon.gif',
    desc: 'Simularea comportamentului dinamic al unui automobil in jocuri 3d'
  },
  {
    id: 3,
    title: 'Bachelor Thesis Project [RO]',
    img:'/images/licenta-icon.gif',
    desc: 'Simularea comportamentului dinamic al unui automobil in jocuri 3d'
  },
  {
    id: 4,
    title: 'Bachelor Thesis Project [RO]',
    img:'/images/licenta-icon.gif',
    desc: 'Simularea comportamentului dinamic al unui automobil in jocuri 3d'
  },
  
  
];

export const services = [
  {
    id: 1,
    title: 'Metin2 Services',
    img:'/images/metin_logo.png',
    model:'/Models/war1.glb',
    desc: 'Game ready animations for every character.',
    status: <span><i className="bi bi-0-circle"></i>Open</span>,
  },
  {
    id: 2,
    title: 'Character Rigging',
    img:'/Models/BlenderLogo/poster.png',
    model:'/Models/BlenderLogo/blender.glb',
    desc: 'I can rig your 3d Character.',
    status: "Closed"
  },
  {
    id: 3,
    title: 'Character Animations',
    img:'/Models/BlenderLogo/poster.png',
    model:'/Models/BlenderLogo/blender.glb',
    desc: 'Create animation for your rigged character.',
    status: "Closed",
  },
  {
    id: 4,
    title: 'Lip Sync',
    img:'/Models/BlenderLogo/poster.png',
    model:'/Models/BlenderLogo/blender.glb',
    desc: 'Lip Sync using Jason Osipa Technique',
    status: "Closed"
  },
  {
    id: 5,
    title: 'Motion Capture',
    img:'/Models/BlenderLogo/poster.png',
    model:'/Models/BlenderLogo/blender.glb',
    desc: 'Cleaning motion capture animations.',
    status: "Closed"
  },
  {
    id: 6,
    title: 'Blender Addons',
    img:'/Models/BlenderLogo/poster.png',
    model:'/Models/BlenderLogo/blender.glb',
    desc: 'Creating addons using Blender Python',
    status: "Closed"
  },
];

export const learnings = [
  {
    id: 1,
    title: 'Lip Sync',
    img:'/images/Visemes/smile.gif',
    desc: 'Jason Osipa Technique'
  },
  {
    id: 2,
    title: 'Blender Python',
    img:'/images/bpy.png',
    desc: 'Use python for repetitive work in Blender.'
  },
  {
    id: 3,
    title: 'Range of Movement',
    img:'/images/range.jpg',
    desc: 'In this section you can find the range of movements in a humanoid body.'
  },
  {
    id: 4,
    title: 'Dictionary',
    img:'/images/animator.jpg',
    desc: 'Some general terms explained about animation'
  },
  {
    id: 5,
    title: 'Motion Capture',
    img:'/images/mocap.png',
    desc: 'What is Motion Capture, and how it works.'
  },
  {
    id: 6,
    title: 'The 12 principles of Animation',
    img:'/images/animator.jpg',
    desc: 'Principles of animation explained'
  },
  {
    id: 7,
    title: 'Import/Export and Formats',
    img:'/images/import.png',
    desc: 'The most common formats and the settings you need to know for import/export.'
  },
  {
    id: 8,
    title: 'Character Rigging',
    img:'/images/animator.jpg',
    desc: 'What is Character rigging and how it works.'
  },
];

export const animations_mt2 = [
  {
    id: 18,
    category:"others",
    hashtag: "#mixamo",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"lt7cRLUVVZY",
    title:"Drunk Animations",
    price:"40 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/drunk.png',
    dis: true,
  },
  {
    id: 17,
    category:"others",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"lt7cRLUVVZY",
    title:"Mogosu Move",
    price:"25 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/mogosu.png',
    dis: true,
  },
  {
    id: 16,
    category:"others",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male",
    embedID:"lt7cRLUVVZY",
    title:"Proposal Animation",
    price:"20 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/proposal.png',
    dis: true,
  },
  {
    id: 15,
    category:"others",
    hashtag: "",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"ymDp2E543Vw",
    title:"Cash Cash",
    price:"30 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/cash.png',
    dis: true,
  },
  {
    id: 14,
    category:"mount",
    hashtag: "#mixamo",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"dYYOva3pbgA",
    title:"Jump Animation",
    price:"20 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/none.png',
    dis: true,
  },
  {
    id: 13,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"ZRoD2YtBPsA",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/wings.png',
    dis: true,
  },
  {
    id: 12,
    category:"dance",
    hashtag: "",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"Mlcva7Flagc",
    title:"Shuffle Dance",
    price:"25 EUR",
    mediafire: "",
    mega:"",
    img:'/images/orange_justice.png',
    dis: true,
  },
  {
    id: 11,
    category:"dance",
    hashtag: "#mixamo",
    hashtag1: "",
    hashtag2:"#female",
    embedID:"8tHmaHj7JYE",
    title:"Samba Dance",
    price:"15 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/samba.png',
    dis: true,
  },
  {
    id: 10,
    category:"dance",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#female",
    embedID:"rUevP_HHb1U",
    title:"Hip Sway",
    price:"25 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/hip_sway.png',
    dis: true,
  },
  
  {
    id: 9,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"1E8Pr0DN0sY",
    title:"Bike Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/bike_icon.png',
    dis: true,
  },
  {
    id: 8,
    category:"dance",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"l07-faRt5mo",
    title:"Boschetarii astia",
    price:"25 EUR",
    mediafire: "",
    mega:"",
    img:'./images/boschetarii.png',
    dis: true,
  },
  {
    id: 7,
    category:"dance",
    hashtag: "#myAnimation",
    hashtag1: "#custom",
    hashtag2:"#male #female",
    embedID:"hVpj2Of_P90",
    title:"Orange Justice Dance",
    price:"Ace2",
    mediafire: "Download",
    mega:"download",
    img:'/images/orange_justice.png',
    dis: true,
  },
 
  {
    id: 6,
    category:"dance",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"z8LLMXDPQz0",
    title:"Fisherman",
    price:"20 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/none.png',
    dis: true,
  },
  {
    id: 5,
    category:"dance",
    hashtag: "myAnimation",
    hashtag1: "",
    hashtag2:"#male",
    embedID:"qrJpkVllAoY",
    title:"Dansul lui Jador",
    price:"25 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/sunt_o_forta.png',
    dis: true,
  },
  {
    id: 4,
    category:"dance",
    hashtag: "myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"obz0pUjdUyU",
    title:"Flag Animation",
    price:"30 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/flag.png',
    dis: true,
  },

  {
    id: 3,
    category:"dance",
    hashtag: "#mixamo",
    hashtag1: "#test",
    hashtag2:"#male",
    embedID:"bsgEfpVWVHo",
    title:"Party Dance",
    price:"Free",
    mediafire: "download",
    mega:"download",
    img:'/images/house.png',
    dis: false,
  },
  {
    id: 2,
    category:"dance",
    hashtag: "#mixamo",
    hashtag1: "#test",
    hashtag2:"#male #female",
    embedID:"rfkv61Jcuts",
    title:"Macarena Dance",
    price:"Free",
    mediafire: "https://www.mediafire.com/file/z4u3memc8plphzp/macarena.rar/file",
    mega:"https://mega.nz/file/741yFLJC#i2TuEyEdsibpplgo9OsgZWzk0wbrgsRnhh5ApUepTPU",
    img:"/images/macarena.png",
    dis: false,
  },

   {
    id: 1,
    category:"dance",
    hashtag: "#mixamo",
    hashtag1: "#test",
    hashtag2:"#female",
    embedID:"BEeS1nIwSBM",
    title:"Candy Shop Dance",
    price:"Free",
    mediafire: "https://www.mediafire.com/file/bc24ad0k5z1o70g/candy.rar/file",
    mega:"https://mega.nz/file/f9MkkDCJ#kS1dMcvIJRvFzMXBa9TVwRp_AxasnKGcunidAf85uTo",
    img:'./images/dance2.png',
    dis: false,
  },
];

export const mounts=[
  {
    id: 11,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"B-iwqMtfE_Y",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_mount.jpg',
    dis: true,
  },
  {
    id: 10,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"FpgVM-UOWzw",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount007.jpg',
    dis: true,
  },
  {
    id: 9,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"DK87jOZalqs",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount006.jpg',
    dis: true,
  },
  {
    id: 8,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"4m_9mRZq36w",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount005.jpg',
    dis: true,
  },
  {
    id: 7,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"8ZhrE-TVnJg",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount004.jpg',
    dis: true,
  },
  {
    id: 6,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"rJ2OwnGJVwc",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount003.jpg',
    dis: true,
  },
  {
    id: 5,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"B-iwqMtfE_Y",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount002.jpg',
    dis: true,
  },
  {
    id: 4,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"B-iwqMtfE_Y",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/n_mount001.jpg',
    dis: true,
  },
  {
    id: 3,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"A2bSZOumtpU",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/s_mount003.jpg',
    dis: true,
  },
  {
    id: 2,
    category:"mount",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"ZRoD2YtBPsA",
    title:"Wings Mount",
    price:"100 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/s_mount002.jpg',
    dis: true,
  },
  {
      id: 1,
      category:"mount",
      hashtag: "#myAnimation",
      hashtag1: "",
      hashtag2:"#male#female",
      embedID:"K_OCHBy8DAI",
      title:"Bike Mount",
      price:"100 EUR",
      mediafire: "Download",
      mega:"download",
      img:'/images/items/s_mount001.jpg',
      dis: true,
  }
];

export const dances=[
  {
    id: 18,
    category:"others",
    hashtag: "#mixamo",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"NmfErm9cV5s",
    title:"Drunk Animation",
    price:"40 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/drunk.png',
    dis: true,
  },
  {
    id: 17,
    category:"others",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"CW3kyMBG8fs",
    title:"Mogosu Move",
    price:"25 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/mogosu.png',
    dis: true,
  },
  {
    id: 16,
    category:"dance",
    hashtag: "",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"JVSp3SI7uBo",
    title:"Sitting Animation",
    price:"30 EUR",
    mediafire: "",
    mega:"",
    img:'/images/sit.png',
    dis: true,
  },
  {
  
    id: 15,
    category:"dance",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male #female",
    embedID:"Y54HKzVGIZM",
    title:"Made in Romania Dance",
    price:"--",
    mediafire: "",
    mega:"",
    img:'/images/made_in_ro.png',
    dis: true,
  },
  {
    id: 14,
    category:"others",
    hashtag: "#myAnimation",
    hashtag1: "",
    hashtag2:"#male",
    embedID:"lt7cRLUVVZY",
    title:"Proposal Animation",
    price:"20 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/proposal.png',
    dis: true,
  },
  {
    id: 13,
    category:"others",
    hashtag: "",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"ymDp2E543Vw",
    title:"Cash Cash",
    price:"30 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/cash.png',
    dis: true,
  },
  {
    id: 12,
    category:"mount",
    hashtag: "#mixamo",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"dYYOva3pbgA",
    title:"Jump Animation",
    price:"20 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/none.png',
    dis: true,
  },
  {
    id: 11,
    category:"dance",
    hashtag: "myAnimation",
    hashtag1: "",
    hashtag2:"#male#female",
    embedID:"obz0pUjdUyU",
    title:"Flag Animation",
    price:"30 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/flag.png',
    dis: true,
  },

{
  
  id: 10,
  category:"dance",
  hashtag: "",
  hashtag1: "",
  hashtag2:"#male #female",
  embedID:"Mlcva7Flagc",
  title:"Shuffle Dance",
  price:"25 EUR",
  mediafire: "",
  mega:"",
  img:'/images/orange_justice.png',
  dis: true,
},
{
  id: 9,
  category:"dance",
  hashtag: "#mixamo",
  hashtag1: "",
  hashtag2:"#female",
  embedID:"8tHmaHj7JYE",
  title:"Samba Dance",
  price:"15 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/samba.png',
  dis: true,

},
{
  id: 8,
  category:"dance",
  hashtag: "#myAnimation",
  hashtag1: "",
  hashtag2:"#female",
  embedID:"rUevP_HHb1U",
  title:"Hip Sway",
  price:"25 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/hip_sway.png',
  dis: true,
},
{
  id: 7,
  category:"dance",
  hashtag: "#myAnimation",
  hashtag1: "",
  hashtag2:"#male #female",
  embedID:"l07-faRt5mo",
  title:"Boschetarii astia",
  price:"25 EUR",
  mediafire: "",
  mega:"",
  img:'./images/boschetarii.png',
  dis: true,
},
{
  id: 6,
  category:"dance",
  hashtag: "#myAnimation",
  hashtag1: "#custom",
  hashtag2:"#male #female",
  embedID:"hVpj2Of_P90",
  title:"Orange Justice Dance",
  price:"Ace2",
  mediafire: "Download",
  mega:"download",
  img:'/images/orange_justice.png',
  dis: true,
},

{
  id: 5,
  category:"dance",
  hashtag: "#myAnimation",
  hashtag1: "",
  hashtag2:"#male #female",
  embedID:"z8LLMXDPQz0",
  title:"Fisherman",
  price:"20 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/none.png',
  dis: true,
},
{
  id: 4,
  category:"dance",
  hashtag: "myAnimation",
  hashtag1: "",
  hashtag2:"#male",
  embedID:"qrJpkVllAoY",
  title:"Dansul lui Jador",
  price:"25 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/sunt_o_forta.png',
  dis: true,
},
{
  id: 3,
  category:"dance",
  hashtag: "#mixamo",
  hashtag1: "#test",
  hashtag2:"#male",
  embedID:"bsgEfpVWVHo",
  title:"Party Dance",
  price:"Free",
  mediafire: "download",
  mega:"download",
  img:'/images/house.png',
  dis: false,
},
{
  id: 2,
  category:"dance",
  hashtag: "#mixamo",
  hashtag1: "#test",
  hashtag2:"#male #female",
  embedID:"rfkv61Jcuts",
  title:"Macarena Dance",
  price:"Free",
  mediafire: "https://www.mediafire.com/file/z4u3memc8plphzp/macarena.rar/file",
  mega:"https://mega.nz/file/741yFLJC#i2TuEyEdsibpplgo9OsgZWzk0wbrgsRnhh5ApUepTPU",
  img:"/images/macarena.png",
  dis: false,
},

 {
  id: 1,
  category:"dance",
  hashtag: "#mixamo",
  hashtag1: "#test",
  hashtag2:"#female",
  embedID:"BEeS1nIwSBM",
  title:"Candy Shop Dance",
  price:"Free",
  mediafire: "https://www.mediafire.com/file/bc24ad0k5z1o70g/candy.rar/file",
  mega:"https://mega.nz/file/f9MkkDCJ#kS1dMcvIJRvFzMXBa9TVwRp_AxasnKGcunidAf85uTo",
  img:'./images/dance2.png',
  dis: false,
}
];

export const pets=[
  {
    id: 10,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"tHStn4VqSzg",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_pet.jpg',
    dis: true,
  },
  {
    id: 9,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"A9KAGrvDFAQ",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet009.jpg',
    dis: true,
  },
  {
    id: 8,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"tHStn4VqSzg",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet008.jpg',
    dis: true,
  },
  {
    id: 7,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",

    embedID:"yRAYa2AJvwY",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet007.jpg',
    dis: true,
  },
  {
    id: 6,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"C8a3iY8dymA",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet006.jpg',
    dis: true,
  },
  {
    id: 5,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"02MGEqUginY",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet005.jpg',
    dis: true,
  },
  {
    id: 4,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"02MGEqUginY",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet004.jpg',
    dis: true,
  },
  {
    id: 3,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"3bWgql-B3Yk",
    title:"Fairy Pet",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet003.jpg',
    dis: true,
  },
  {
    id: 2,
    category:"pet",
    hashtag: "#pet",
    hashtag1: "",
    hashtag2:"",
    embedID:"tjLpxMJmj4k",
    title:"Mickey & Minnie Mouse Pet",
    price:"40 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/pet002.jpg',
    dis: true,
  },
  {
  id: 1,
  category:"pet",
  hashtag: "#pet",
  hashtag1: "",
  hashtag2:"",
  embedID:"tjLpxMJmj4k",
  title:"Kitty Pets",
  price:"50 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/pet001.jpg',
  dis: true,
},
];

export const costumes=[
  {
    id: 63,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"",
    embedID:"GYhLBu7G1N4",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_outfit.jpg',
    dis: true,
  },
  {
    id: 62,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"bJGF1_vB0LM",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c61shf.jpg',
    dis: true,
  },
  {
    id: 61,
    category:"costumes",
    hashtag: "#ninja #female",
    hashtag1: "",
    hashtag2:"nf",
    embedID:"ST2NZrLgEEI",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c62nf.jpg',
    dis: true,
  },
  {
    id: 60,
    category:"costumes",
    hashtag: "#shaman #male",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"L-De0bmf5Po",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c60shm.jpg',
    dis: true,
  },
  {
    id: 59,
    category:"costumes",
    hashtag: "#war #male",
    hashtag1: "",
    hashtag2:"wm",
    embedID:"ZqhNSJkIGvQ",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c59wm.jpg',
    dis: true,
  },
  {
    id: 58,
    category:"costumes",
    hashtag: "#ninja #female",
    hashtag1: "",
    hashtag2:"nf",
    embedID:"sOBYyZR2dt8",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c58nf.jpg',
    dis: true,
  },
  {
    id: 57,
    category:"costumes",
    hashtag: "#shaman #male",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"vVmICazTdzU",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c57shm.jpg',
    dis: true,
  },
  {
    id: 56,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"Zg3Zn3TXh10",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c55shf.jpg',
    dis: true,
  },
  {
    id: 55,
    category:"costumes",
    hashtag: "#ninja #male",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"XkiazRxSdCg",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c07nm.jpg',
    dis: true,
  },
  {
    id: 54,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sf",
    embedID:"Z3sE-kvoRno",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c54sf.jpg',
    dis: true,
  },
  {
    id: 53,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nf",
    embedID:"AySad46ua1Y",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c52nf.jpg',
    dis: true,
  },
  {
    id: 52,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"JCR_kfrVEB4",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c51shm.jpg',
    dis: true,
  },
  {
    id: 51,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"6_Y82XNaWco",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c50shf.jpg',
    dis: true,
  },
  {
    id: 50,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"wV5vpmuM5B8",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c49shf.jpg',
    dis: true,
  },
  {
    id: 49,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"MX6z_6DvjuY",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c36nm.jpg',
    dis: true,
  },
  {
    id: 48,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"GYhLBu7G1N4",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c45shf.jpg',
    dis: true,
  },
  {
    id: 47,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sm",
    embedID:"gwXl1DXMiYw",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c44sm.jpg',
    dis: true,
  },
  {
    id: 46,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"OTKwhUhTxpQ",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c43shf.jpg',
    dis: true,
  },
  {
    id: 45,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"wm",
    embedID:"HXbjaZXh4Zc",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c42wm.jpg',
    dis: true,
  },
  {
    id: 44,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sm",
    embedID:"8yAS1d6jB9k",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c43sm.jpg',
    dis: true,
  },
  {
    id: 43,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"wm",
    embedID:"HE9NI5VSAYI",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c43wm.jpg',
    dis: true,
  },
  {
    id: 42,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"hbDl0Zecx90",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c41shm.jpg',
    dis: true,
  },
  {
    id: 41,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"8YPdzSj3z5k",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c33shf.jpg',
    dis: true,
  },
  {
    id: 40,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"6ielZe5atM4",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c35nm.jpg',
    dis: true,
  },
  {
    id: 39,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sm",
    embedID:"vYUHTAY0cOQ",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c34sm.jpg',
    dis: true,
  },

  {
    id: 38,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"wf",
    embedID:"JXLjRpibbso",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c00wf.jpg',
    dis: true,
  },
  {
    id: 37,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"7wggg5rjAOY",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c06nm.jpg',
    dis: true,
  },
  {
    id: 36,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"skELDiEYEeg",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c05nm.jpg',
    dis: true,
  },
  {
    id: 35,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"hXZUwAD7FYE",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c22shf.jpg',
    dis: true,
  },
  {
    id: 34,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"I9qIuWbp3MI",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c21shf.jpg',
    dis: true,
  },
  {
    id: 33,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"iZXqkxYVBAY",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c20shf.jpg',
    dis: true,
  },
  {
    id: 32,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sm",
    embedID:"ZkOVXjyu2B8",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c02sm.jpg',
    dis: true,
  },
  {
    id: 31,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"sm",
    embedID:"WoEMxT3atLI",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c01sm.jpg',
    dis: true,
  },
  {
  
    id: 30,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"wm",
    embedID:"7L28hMKwM10",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c02wm.jpg',
    dis: true,
  },
  {
  id: 29,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"wm",
  embedID:"B3AR7TvEBmI",
  title:"",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c01wm.jpg',
  dis: true,
  },
  {
  
    id: 28,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"gXzw_H0H-Sk",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c04nm.jpg',
    dis: true,
  },
  {
  id: 27,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"nm",
  embedID:"sHsAwjz_bME",
  title:"",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c03nm.jpg',
  dis: true,
  },
  {
  
    id: 26,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nm",
    embedID:"UcqXgZMj6mk",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c02nm.jpg',
    dis: true,
  },
  {
  id: 25,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"nm",
  embedID:"hhfJvcfBjj0",
  title:"",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c01nm.jpg',
  dis: true,
  },
  {
    id: 24,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"86HJ7X9A7I0",
    title:"",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c23shm.jpg',
    dis: true,
    },
  {
    id: 23,
    category:"costumes",
    hashtag: "#shaman #male",
    hashtag1: "",
    hashtag2:"shm",
    embedID:"aZVImoaETb0",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c02shm.jpg',
    dis: true,
  },
  {
  id: 22,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shm",
  embedID:"t0cUW-x-3bw",
  title:"",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c01shm.jpg',
  dis: true,
  },
  {
  
    id: 21,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"nf",
    embedID:"Vl3G4IdTWss",
    title:"Outfit 04",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c02nf.jpg',
    dis: true,
  },
  {
  id: 20,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"nf",
  embedID:"zcHI228qocU",
  title:"",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c01nf.jpg',
  dis: true,
  },
{
  id: 19,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"H4gsIqkx5Xc",
  title:"Outfit 04",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c19shf.jpg',
  dis: true,
},
{
id: 18,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"osI6SU81ndg",
title:"",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c18shf.jpg',
dis: true,
},
{
  id: 17,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"nYWnzXdslbg",
  title:"Outfit 02",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c17shf.jpg',
  dis: true,
},
{
id: 16,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"efw8oxzdOiE",
title:"Outfit 01",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c16shf.jpg',
dis: true,
},
  {
    id: 15,
    category:"costumes",
    hashtag: "#shaman #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"t0cUW-x-3bw",
    title:"Outfit 15",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c15shf.jpg',
    dis: true,
  },
  {
    id: 14,
    category:"costumes",
    hashtag: "#ninja #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"B3AR7TvEBmI",
    title:"Outfit 14",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c14shf.jpg',
    dis: true,
  },
  {
    id: 13,
    category:"costumes",
    hashtag: "#ninja #female",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"7E0UPv6i-5I",
    title:"Outfit 13",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c13shf.jpg',
    dis: true,
  },
  {
    id: 12,
    category:"costumes",
    hashtag: "#shaman #male #shaman #female",
    hashtag1: "couple",
    hashtag2:"shf",
    embedID:"0E_LTaoxBwo",
    title:"Outfit 12",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c12shf.jpg',
    dis: true,
  },
  {
    id: 11,
    category:"costumes",
    hashtag: "#war #male #shaman female",
    hashtag1: "couple",
    hashtag2:"shf",
    embedID:"zNAsHtlz9IQ",
    title:"Outfit 11",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c11shf.jpg',
    dis: true,
  },
  {
    id: 10,
    category:"costumes",
    hashtag: "#ninja #male",
    hashtag1: "",
    hashtag2:"shf",
    embedID:"iM6aQSejsvE",
    title:"Outfit 10",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/c10shf.jpg',
    dis: true,
  },
  {
  id: 9,
  category:"costumes",
  hashtag: "#shaman #female #ninja #male",
  hashtag1: "#couple",
  hashtag2:"shf",
  embedID:"iM6aQSejsvE",
  title:"Outfit 09",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c09shf.jpg',
  dis: true,
},
{
  id: 8,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"nBU5xi25Zm4",
  title:"Outfit 08",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c08shf.jpg',
  dis: true,
},
{
id: 7,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"sLNAMD0Jtkc",
title:"Outfit 07",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c07shf.jpg',
dis: true,
},
{
  id: 6,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"0eYpE6yoTvw",
  title:"Outfit 06",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c06shf.jpg',
  dis: true,
},
{
id: 5,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"5mMwOlaemPw",
title:"Outfit 05",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c05shf.jpg',
dis: true,
},
{
  id: 4,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"s1x_IMz0xR4",
  title:"Outfit 04",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c04shf.jpg',
  dis: true,
},
{
id: 3,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"WA91bgDSwE4",
title:"Outfit 03",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c03shf.jpg',
dis: true,
},
{
  id: 2,
  category:"costumes",
  hashtag: "#shaman #female",
  hashtag1: "",
  hashtag2:"shf",
  embedID:"Z0Z1ykqtpF4",
  title:"Outfit 02",
  price:"--",
  mediafire: "Download",
  mega:"download",
  img:'/images/items/c02shf.jpg',
  dis: true,
},
{
id: 1,
category:"costumes",
hashtag: "#shaman #female",
hashtag1: "",
hashtag2:"shf",
embedID:"f2r_QX-nu44",
title:"Outfit 01",
price:"--",
mediafire: "Download",
mega:"download",
img:'/images/items/c01shf.jpg',
dis: true,
},
];

export const renders=[

];
export const monsters=[
  {
    id: 3,
    category:"stones",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"K34DrGUrnh0",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_monster.jpg',
    dis: true,
  },
  {
    id: 2,
    category:"stones",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"K34DrGUrnh0",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/monster002.jpg',
    dis: true,
  },
  {
    id: 1,
    category:"stones",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"K34DrGUrnh0",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/monster001.jpg',
    dis: true,
  },
];
export const bosses=[
  {
    id: 2,
    category:"stones",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"6-cidVC-A8Q",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_boss.jpg',
    dis: true,
  },
  {
    id: 1,
    category:"stones",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"6-cidVC-A8Q",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/boss001.jpg',
    dis: true,
  },
];
export const npcs=[
  {
    id: 10,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"FsVPfmgjLks",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_npc.jpg',
    dis: true,
  },
  {
    id: 9,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc009.jpg',
    dis: true,
  },
  {
    id: 8,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc008.jpg',
    dis: true,
  },
  {
    id: 7,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc007.jpg',
    dis: true,
  },
  {
    id: 6,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc006.jpg',
    dis: true,
  },
  {
    id: 5,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc005.jpg',
    dis: true,
  },
  {
    id: 4,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc004.jpg',
    dis: true,
  },
  {
    id: 3,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc003.jpg',
    dis: true,
  },
  {
    id: 2,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"pTOOfG5KvXc",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc002.jpg',
    dis: true,
  },
  {
    id: 1,
    category:"npc",
    hashtag: "#monster",
    hashtag1: "#boss",
    hashtag2:"",
    embedID:"FsVPfmgjLks",
    title:"Moster Boss 01",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/npc001.jpg',
    dis: true,
  },
];
export const stones=[
  {
    id: 4,
    category:"stones",
    hashtag: "#metinstone",
    hashtag1: "#minecraft",
    hashtag2:"",
    embedID:"gJ__xsKcJak",
    title:"Minecraft Ore Stons",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/custom_stone.jpg',
    dis: true,
  },
  {
    id: 3,
    category:"stones",
    hashtag: "#metinstone",
    hashtag1: "#minecraft",
    hashtag2:"",
    embedID:"EN0n9tyUYeU",
    title:"Minecraft Ore Stons",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/stone003.jpg',
    dis: true,
  },
  {
    id: 2,
    category:"stones",
    hashtag: "#metinstone",
    hashtag1: "#minecraft",
    hashtag2:"",
    embedID:"gJ__xsKcJak",
    title:"Minecraft Ore Stons",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/stone002.jpg',
    dis: true,
  },
  {
    id: 1,
    category:"stones",
    hashtag: "#metinstone",
    hashtag1: "#minecraft",
    hashtag2:"",
    embedID:"5mjykRIuKV4",
    title:"Minecraft Ore Stons",
    price:"--",
    mediafire: "Download",
    mega:"download",
    img:'/images/items/stone001.jpg',
    dis: true,
  },
];

export const orders=[
  {
    id: 1,
    category:"order",
    hashtag: "#inProgress",
    hashtag1: "#orderOnly",
    hashtag2:"",
    embedID:"q3s0sHMeHSg",
    title:"Sitting animation",
    price:"30 EUR",
    mediafire: "Download",
    mega:"download",
    img:'/images/none.png',
    dis: true,
  },
  {
  id: 2,
  category:"order",
  hashtag: "#inProgress",
  hashtag1: "#orderOnly",
  hashtag2:"",
  embedID:"7Iy1OEmcnmg",
  title:"Cash Cash Emote",
  price:"30 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/none.png',
  dis: true,
},
{
  id: 3,
  category:"order",
  hashtag: "#inProgress",
  hashtag1: "#orderOnly",
  hashtag2:"",
  embedID:"wbk6EEkpSE4",
  title:"I'm So Excited",
  price:"25 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/none.png',
  dis: true,
},
{
  id: 4,
  category:"order",
  hashtag: "#inProgress",
  hashtag1: "#orderOnly",
  hashtag2:"#interactive",
  embedID:"g1G57ORB-s4",
  title:"Hug Idle Animation",
  price:"50 EUR",
  mediafire: "Download",
  mega:"download",
  img:'/images/none.png',
  dis: true,
}, 
];